@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
font-family: "Nunito", sans-serif;
margin: 0;
padding: 0;
box-sizing: border-box;
outline: none;
border: none;
text-decoration: none;

transition: 0.2s linear;
}
html {
font-size: 62.5%;
overflow-x: hidden;
scroll-padding-top: 4rem;
scroll-behavior: smooth;
}
html::-webkit-scrollbar {
width: 0.8rem;
}
html::-webkit-scrollbar-track {
background: transparent;
}
html::-webkit-scrollbar-thumb {
background: #fff; 
border-radius: 5rem;
}
body {
overflow-x: hidden;
}
section {
padding: 2rem 7%;
}
.heading {
text-align: center;
color: #fff;
text-transform: uppercase;
padding-bottom: 3.5rem;
padding-top: 7rem;
font-size: 4rem;
}
.heading1 {
text-align: center;
color: #fff;
text-transform: uppercase;
padding-bottom: 3.5rem;
padding-top: 6rem;
font-size: 4rem;
}

.heading span {
color: black;
text-transform: uppercase;
}
.btn {
  margin-top: 1rem;
  display: inline-block;
  padding: 0.9rem 3rem;
  font-size: 1.7rem;
  color: black; /* Text color */
  background: transparent; /* Transparent background */
  cursor: pointer;
  border: none; /* Remove border */
  border-radius: 1.5625rem; /* Border radius of 25px in rem units */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow */
  transition: background 0.3s, color 0.3s, box-shadow 0.3s; /* Smooth transitions */
}

.btn:hover {
  background: rgba(63, 147, 85, 0.1); /* Light green tint on hover */
  color: #3f9355; /* Change text color on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Increase shadow on hover */
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.header {
  background: aliceblue;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 7%;
  border-bottom: 0.2rem solid #3f9355;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.header .logo-container img {
  max-width: 75px;
  animation: fadeIn 1s ease-out; /* Apply animation here */
}

.header .logo-container:hover {
  color: #679acd;
}

.header .navbar a {
  margin: 0 1rem;
  font-size: 1.6rem;
  color: #3f9355;
}

.header .navbar a:hover {
  color: #679acd;
  border-bottom: 0.1rem solid #679acd;
  padding-bottom: 0.5rem;
}

.header .icons div {
  color: #3f9355;
  cursor: pointer;
  font-size: 2.5rem;
  margin-left: 2rem;
}

.header .icons div:hover {
  color: #679acd;
}

.logo-container {
  display: flex;
  align-items: center;
}

.brand-name {
  margin-left: 10px;
  font-size: 1.6rem;
  color: #3f9355;
  text-decoration: none;
}

#menu-btn {
display: none;
}
.home{
background: #679acd;
}
.home .row {
display: flex;
align-items: center;
flex-wrap: wrap;
}
.home .row .image {
flex: 1 1 45rem;
padding-top:2rem;
}
.home .row .image img {
width: 100%;
}
.home .row .content {
flex: 1 1 45rem;
padding: 2rem;
}
.home .row .content h3 {
font-size: 2rem;
color: black;
padding-top:2rem;
}
.home .row .content p {
font-size: 1.6rem;
color: black;
padding: 1rem 0;
line-height: 1.8;
text-align: justify; /* Add this line */
}

.box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 1.5rem;
}

.courses .box-container .box {
  text-align: center;
  box-shadow: 0 8px 16px 0 rgba(103, 154, 205, 0.4);
  transition: box-shadow 0.3s ease-in-out, border-radius 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 2rem; /* Adjust the radius value as needed */
}

.courses .box-container .box .content {
  padding: 1.2rem;
  height: 100%; /* Use height 100% to occupy full height of parent */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.courses .box-container .box h3 {
  color: black;
  font-size: 2rem;
  padding: 1rem 0;
}

.courses .box-container .box p {
  font-size: 1.6rem;
  line-height: 1.8;
  color: black;
  padding: 1rem 0;
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.courses .box-container .box:hover {
transition: 0.5s;
}
.courses .box-container .box .course-icon {
  font-size: 6rem; /* Adjust the size as needed */
  color: #679acd;
}



.course-btn {
  background: transparent; /* Fully transparent background */
  color: #3f9355; /* Adjust text color to be visible */
  border: none; /* Remove border */
  padding: 1rem 2rem;
  font-size: 1.4rem;
  cursor: pointer;
  transition: background 0.3s, color 0.3s, box-shadow 0.3s;
  margin-top: auto; /* Align button to bottom of the box */
  width: fit-content; /* Make the button's width fit its content */
  align-self: center; /* Center the button horizontally */
  border-radius: 1.5625rem; /* Border radius of 25px in rem units */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow */
}

.course-btn:hover {
  background: rgba(63, 147, 85, 0.1); /* Light green tint on hover */
  color: #3f9355; /* Change text color on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Increase shadow on hover */
}


/* Base button styles */

/* Individual hover colors for each course box */
.courses .box-container .box.python:hover {
  background-color: #fff9c4; /* Light Yellow for Python */
}
.courses .box-container .box.reactjs:hover {
  background-color: #ffe0e0; /* Light Pink for React.js */
}
.courses .box-container .box.oracle:hover {
  background-color: #e1bee7; /* Light Purple for Oracle */
}
.courses .box-container .box.javascript:hover {
  background-color: #e0f7fa; /* Light Cyan for JavaScript */
}
.courses .box-container .box.mysql:hover {
  background-color: #f0f4c3; /* Light Lime for SQL Fundamentals */
}
.courses .box-container .box.angular:hover {
  background-color: #ffe0b2; /* Light Orange for Angular */
}

.contact .row {
  display: flex;
  background: #fff;
  flex-wrap: wrap;
  gap: 1rem;
  box-shadow: 0 8px 16px 0 rgba(103, 154, 205, 0.4);
  border-radius: 2rem; /* Add border radius to match the box styling */
  padding: 2rem; /* Add padding to prevent content from touching edges */
}

.contact .row form {
  flex: 1 1 45rem;
  padding: 2rem; /* Adjust padding to ensure content is not cramped */
  text-align: center;
  
 
}

.contact .row form h3 {
  text-transform: uppercase;
  font-size: 2.5rem; /* Adjust font size to fit the form */
  color: black;
  margin-bottom: 1rem; /* Ensure spacing below the heading */
}

.contact .row form .inputBox {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background: #fff;
  border-radius: 2rem; /* Apply border-radius to the container */
  box-shadow: 0 4px 8px rgba(103, 154, 205, 0.4); /* Shadow for input boxes */
  overflow: hidden; /* Ensure child elements respect the container's border-radius */
  padding-right: 1rem; /* Add padding to prevent content from touching the right edge */
}

.contact .row form .inputBox span {
  color:  #679acd;
  font-size: 2rem;
  padding-left: 1rem; /* Adjust padding for better alignment */
}



.contact .row form .inputBox input,
.contact .row form .inputBox textarea {
  width: 100%;
  padding: 1rem; /* Adjust padding for better spacing */
  font-size: 1.6rem; /* Adjust font size for better readability */
  color: black;
  border: none;
  outline: none;
  border-radius: 2rem; /* Apply border-radius directly to inputs and textareas */
}

.contact .row form .inputBox textarea {
  resize: none; /* Prevents resizing of the textarea */
  height: 15rem; /* Sets a fixed height for the textarea */
}

.contact .row .content {
  flex: 1 1 45rem;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  padding: 2rem; /* Adjust padding to ensure content is not cramped */
  text-align: center;
}

.contact .row .content h3 {
  font-size: 2.5rem;
  color: black;
  margin-bottom: 1rem;
}

.contact .row .content p {
  font-size: 1.6rem; /* Adjust font size for better readability */
  color: #333;
  margin: 0.5rem 0;
}

.contact .row .content p strong {
  font-size: 1.6rem; /* Match font size with content */
  color: black;
}

.contact .row form .error {
  color: red; /* Style for error messages */
  font-size: 1.4rem; /* Adjust font size for error messages */
  margin-top: 0.5rem;
}



.footer {
  background: #f0f8ff; /* Slightly adjusted color for better contrast */
  text-align: center;
  padding: 2rem 0; /* Added padding for spacing */
}

.footer .share {
  padding: 1rem 0;
}

.footer .share a {
  height: 4rem; /* Slightly reduced size */
  width: 4rem; /* Slightly reduced size */
  line-height: 4rem; /* Adjusted line height to match height */
  font-size: 1.8rem; /* Adjusted font size */
  color: #fff; /* White text color for better visibility */
  background: #3f9355; /* Changed to match link color */
  margin: 0.3rem;
  border-radius: 50%;
  display: inline-flex; /* Ensures centering of text */
  align-items: center; /* Vertically centers text */
  justify-content: center; /* Horizontally centers text */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Smooth transition */
}

.footer .share a:hover {
  background-color: #679acd; /* Darker background on hover */
  border-color: #3f9355; /* Change border color on hover */
}

.footer .links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem 0;
  gap: 1rem;
}

.footer .links a {
  padding: 0.7rem 1.5rem; /* Adjusted padding for better spacing */
  color: #3f9355;
  border: 0.2rem solid #679acd;
  font-size: 1.8rem; /* Adjusted font size */
  border-radius: 0.3rem; /* Added border radius for rounded corners */
  text-decoration: none; /* Remove underline */
  transition: background 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.footer .links a:hover {
  background: #679acd; /* Background color on hover */
  color: #fff; /* Change text color on hover for contrast */
}

.footer .credit {
  font-size: 1.8rem; /* Adjusted font size */
  color: #3f9355;
  padding: 1.5rem;
}

.footer .credit span {
  color: #679acd; /* Changed span color to match link color */
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Default styles */
.courseimage {
  width: 50%; /* Default width */
  margin-left: auto; /* Center the image horizontally */
  margin-right: auto; /* Center the image horizontally */
  display: block; /* Ensure the image is displayed as a block element */
  
}



.container {
  width: 80%;
  margin: auto;
  overflow: hidden;
}



.course-detail {
  margin-top: 20px;
}

.course-detail h2 {
  text-align: center;
  color: #333;
}


.course-info {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.course-description {
  flex: 1;
  padding: 20px;
  font-size: 15px;
}

.course-description p {
  line-height: 1.6;
  color: black;
}

.course-description h3 {
  margin-top: 1.5rem;
  font-size: 1.8rem;
  color: black;
}

.course-description ul {
  margin-top: 1rem;
}

.course-description ul li {
  line-height: 1.8;
  color: black;
}

.about-us-detail {
  background: #fff;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.about-us-detail h2 {
  text-align: center;
  color: #333;
}

.about-us-info {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.about-us-description {
  flex: 1;
  padding: 20px;
  font-size: 15px;
}

.about-us-description p {
   margin-top: 1.5rem;
  line-height: 1.6;
  color: black;
}

.about-us-description h3 {
  margin-top: 1.5rem;
  font-size: 1.8rem;
  color: black;
}

.about-us-description ul {
  margin-top: 1.5rem;
  margin-left: 1rem;
  font-size: 1.5rem;
}

.about-us-description ul li {
  line-height: 1.8;
  color: black;
}




.profile-info {
  display: inline-block;
  margin-left: 2rem;
  font-size: 1.4rem;
  color: #3f9355;
}

.header .profile-info span {
  font-size: 1.6rem;
  color: #3f9355;
  font-weight: bold;
}

.header .profile-info:hover {
  color: #679acd;
}


.error {
  color: red;
  font-size: 0.9em;
  margin-top: 0.25em;
}

.features-section {
  background-color: #f8f9fa;
  padding: 10px 0;
}

.heading {
  text-align: center;
  margin-bottom: 50px;
}

.row {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-between;
  margin-bottom: 40px;
}

.col {
  flex: 0 0 48%; 
  max-width: 48%; 
}

.feature-item {
  text-align: center;
  padding: 20px;
  background-color:  aliceblue;
  border-radius: 2rem;
  box-shadow: 0 8px 16px 0 rgba(103, 154, 205, 0.4);
  transition: transform 0.3s ease-in-out;
  margin-bottom: 20px; /* Add spacing between items */
}

.feature-item:hover {
  transform: translateY(-5px);
}

.feature-item h3 {
  font-size: 1.5rem;
  margin-top: 20px;
}

.feature-item p {
  color: black;
  line-height: 1.6;
}

.feature-item svg {
  color: #679acd;
  margin-bottom: 10px;
}

/*media queries*/

@media (max-width: 830px) {
 #menu-btn {
    display: inline-block;
    width: 3rem; /* Adjust the size as needed */
    height: 3rem; /* Same as width to make it circular */
    background-color: #3f9355; /* Background color of the button */
    border-radius: 50%; /* Makes the button circular */
    color: white; /* Text color (for icons) */
    text-align: center; /* Center align icon */
    line-height: 3rem; /* Align icon vertically */
    font-size: 1.5rem; /* Adjust icon size as needed */
    cursor: pointer; /* Change cursor to pointer on hover */
    position: relative; /* Position for better alignment if needed */
    transition: background-color 0.3s ease; /* Smooth transition for background color change */
  }

  #menu-btn:hover {
    background-color: #679acd; /* Background color on hover */
  }
.header .navbar {
position: absolute;
top: 100%;
right: -100%;
background: aliceblue;
width: 30rem;
height: calc(108vh - 9.5rem);
}
.header .navbar.active {
right: 0;
}
.header .navbar a {
color: #3f9355;
display: block;
margin: 1.5rem;
padding: 0.5rem;
font-size: 2rem;
}
.home .row{
flex-direction: column; /* Change flex direction to stack elements vertically */
align-items: center; /* Center align items horizontally */
text-align: center; /* Center align text */
}
.home .row .content {
flex: 1 1 20rem;
padding-top: 6rem;
}

.heading {
padding-top: 10rem;
}

}
@media (max-width: 450px) {
html {
font-size: 50%;
}
.home .row .content {
flex: 1 1 20rem;
padding-top: 6rem;
}

.header .logo img {
max-width: 40px;
}
.heading {
padding-top: 10rem;
}
}
/* media queries  */
@media (max-width: 991px) {
html {
font-size: 55%;
}
.header {
padding: 1.5rem 2rem;
}
section {
padding: 2rem;
}

.home .row .content {
padding-top: 10rem;
}
}
/* New media query for screens with a maximum width of 1050px */
@media (min-width: 992px) and (max-width: 1050px) {
.home .row .content,
.home .row .image {
flex: 1 1 calc(50% - 2rem); /* Adjust flex basis to half the container width with spacing */
padding: 1rem; /* Add padding for spacing between content and image */
}

}
@media (min-width: 830px) and (max-width: 1050px){
.home .row .image{
padding-top:6rem;
}
}


@media (max-width: 768px) {


  .heading1 {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
    padding-top: 10rem;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
  }
  .contact .row {
    flex-direction: column; /* Stack the content vertically */
    padding: 2rem; /* Add padding for better spacing */
  }

  .contact .row .content,
  .contact .row form {
    padding: 2rem; /* Reduce padding for smaller screens */
  }

  .contact .row form h3 {
    font-size: 2.5rem; /* Reduce font size for smaller screens */
  }

  .contact .row .content h3 {
    font-size: 2rem; /* Reduce font size for smaller screens */
  }

  .contact .row .content p,
  .contact .row form .inputBox span {
    font-size: 1.2rem; /* Reduce font size for smaller screens */
  }

  .contact .row form .inputBox input,
  .contact .row form .inputBox textarea {
    padding: 1rem; /* Reduce padding for inputs and textareas */
  }

  
  .heading1 {
    font-size: 1.5rem;
    padding-top: 10rem;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
  }

    .col {
    flex: 0 0 100%; /* Ensure each item takes full width on smaller screens */
    max-width: 100%; /* Allow items to stretch to full width */
  }
  
  .feature-item {
    padding: 15px;
  }

  .feature-item h3 {
    font-size: 1.3rem;
  }

  .course-detail .course-info {
    flex-direction: column;
    padding: 2rem;
  }

  .course-detail .course-description {
    padding: 2rem;
  }

  .course-detail .course-description h3 {
    font-size: 1.5rem;
  }

  .course-detail .course-description ul {
    margin-left: 1rem;
  }

  .about-us-detail .about-us-info {
    flex-direction: column;
    padding: 2rem;
  }

  .about-us-detail .about-us-description {
    padding: 2rem;
  }

  .about-us-detail .about-us-description h3 {
    font-size: 1.5rem;
  }

  .about-us-detail .about-us-description ul {
    margin-top: 1.5rem;
    margin-left: 1rem;
    font-size: 1.5rem;
  }

  .about-us-detail .about-us-description p {
    margin-top: 1.5rem;
    font-size: 1.5rem;
    line-height: 1.6;
  }

}


/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure modal is on top of everything */
}

/* Modal Content */
.modal-content {
  background: #fff; /* White background */
  padding: 7px; /* Reduced padding */
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); /* Slightly smaller box shadow */
  max-width: 400px; /* Slightly smaller width */
  width: 100%;
  position: relative;
}

/* Close Icon */
.close-icon {
  position: absolute;
  top: 8px; /* Reduced top position */
  right: 8px; /* Reduced right position */
  cursor: pointer;
  font-size: 20px; /* Slightly smaller font size */
  color: #679acd; /* Light gray color */
  transition: color 0.3s ease;
}

.close-icon:hover {
  color: #83b4e6; /* Darker gray color on hover */
}

/* Modal Title */
.modal-content h2 {
  margin-top: 0;
  font-size: 20px; /* Reduced font size */
  text-align: center;
  margin-bottom: 10px; /* Further reduced bottom margin */
}

/* Form Styling */
.modal-content form {
  display: flex;
  flex-direction: column;
}

.modal-content label {
  margin-bottom: 4px; /* Further reduced bottom margin */
  font-size: 14px; /* Smaller font size */
  font-weight: bold;
}

/* Input and Select Styling */
.modal-content input[type="text"],
.modal-content input[type="email"],
.modal-content select {
  padding: 4px; /* Reduced padding */
  margin-bottom: 8px; /* Further reduced bottom margin */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px; /* Smaller font size */
}

.modal-content input[type="text"]:focus,
.modal-content input[type="email"]:focus,
.modal-content select:focus {
  outline: none;
  border-color: #5c9ed8; /* Blue outline on focus */
  box-shadow: 0 0 4px rgba(92, 158, 216, 0.4); /* Slightly smaller blue shadow */
}

/* Course Name Input */
.modal-content input[name="courseName"] {
  padding: 4px; /* Reduced padding */
  margin-bottom: 8px; /* Further reduced bottom margin */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px; /* Smaller font size */
  background-color: #f9f9f9; /* Light gray background */
  color: #333; /* Dark gray text */
  cursor: pointer;
}

.modal-content input[name="courseName"]:focus {
  outline: none;
  border-color: #5c9ed8; /* Blue outline on focus */
  box-shadow: 0 0 4px rgba(92, 158, 216, 0.4); /* Slightly smaller blue shadow */
}

.modal-content button {
  padding: 8px 16px; /* Further reduced padding */
  margin-top: 10px; /* Further reduced top margin */
  border: none; /* Remove border */
  border-radius:1.5625rem; /* Border radius */
  background-color: transparent; /* Transparent background */
  color: #5c9ed8; /* Text color */
  font-size: 14px; /* Smaller font size */
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add shadow */
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  max-width: 150px; /* Set a maximum width */
  width: fit-content; /* Shrink to fit content */
}

.modal-content button:hover {
  background-color: rgba(92, 158, 216, 0.1); /* Light blue tint on hover */
  color: #4a8ac2; /* Darker text color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Increase shadow on hover */
}


/* Confirmation Message */
.confirmation-message {
  text-align: center;
  padding: 20px;
}

.confirmation-message h2 {
  color: #4caf50; /* Green color for success message */
  font-size: 22px; /* Slightly larger font size for emphasis */
  margin-bottom: 10px;
}

.confirmation-message p {
  font-size: 16px; /* Standard font size for message text */
  color: #333; /* Dark gray color for readability */
  margin-bottom: 20px; /* Space below the message */
}

.confirmation-message .btn {
  background-color: #3f9355; /* Green button color for confirmation */
  color: white;
  padding: 10px 20px; /* Comfortable padding */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px; /* Slightly larger font size for readability */
  transition: background-color 0.3s ease;
}

.confirmation-message .btn:hover {
  background-color: #45a049; /* Slightly darker green on hover */
}

.confirmation-message .btn:focus {
  outline: none;
  box-shadow: 0 0 4px rgba(76, 175, 80, 0.4); /* Green shadow on focus */
}


/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure modal is on top of everything */
}

/* Modal Content */
.modal-content {
  background: #fff; /* White background */
  padding: 20px; /* Comfortable padding */
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); /* Slightly smaller box shadow */
  max-width: 400px; /* Maximum width */
  width: 100%;
  position: relative;
}

/* Close Icon */
.close-icon {
  position: absolute;
  top: 8px; /* Position at top right */
  right: 8px;
  cursor: pointer;
  font-size: 20px; /* Icon size */
  color: #679acd; /* Light gray color */
  transition: color 0.3s ease;
}

.close-icon:hover {
  color: #83b4e6; /* Darker gray color on hover */
}

/* Confirmation Message */
.confirmation-message {
  text-align: center;
}

.confirmation-message h2 {
  color: #4caf50; /* Green color for success message */
  font-size: 22px; /* Slightly larger font size */
  margin-bottom: 10px;
}

.confirmation-message p {
  font-size: 16px; /* Standard font size */
  color: #333; /* Dark gray color for readability */
  margin-bottom: 20px; /* Space below the message */
}

.confirmation-message .btn {
  background-color: #3f9355; /* Green button color for confirmation */
  color: white;
  padding: 10px 20px; /* Comfortable padding */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px; /* Slightly larger font size */
  transition: background-color 0.3s ease;
}

.confirmation-message .btn:hover {
  background-color: #45a049; /* Slightly darker green on hover */
}
